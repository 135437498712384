import {
  INFO_MESSAGE_PAGE_ROUTE,
  INFO_MESSAGE_PAGE_PARAMS,
} from '~/constants/infoMessages';
import { REQUIRED_AUTH_PAGES_WITH_CHILDREN } from '~/constants/general';
import { useAuthStore } from '~/stores/auth';

export default defineNuxtRouteMiddleware(({ params }) => {
  const { getUserData } = useAuthStore();

  if (
    getUserData.loggedIn &&
    getUserData.blockLite &&
    (params.pageType || params.mode) !== REQUIRED_AUTH_PAGES_WITH_CHILDREN.game.fun
  ) {
    return navigateTo({ path: `${INFO_MESSAGE_PAGE_ROUTE}/${INFO_MESSAGE_PAGE_PARAMS.blockLite}` });
  }
});
